@import '~fe-common-styles/lib/index.css';

#app {
  #outer-wrapper {
    height: 100%;
  }

  #inner-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  #content {
    height: 100%;
    padding: 0px;
    display: flex;
    flex: 2 1 0%;
    overflow: auto;
  }

  #left-panel {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  #right-panel {
    order: 2;
    height: 100%;
  }

  #app-info {
    font-size: 1.8rem;
    color: #2d2926;
    line-height: 2.2rem;
    flex-wrap: wrap;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10000;
    text-align: left;

    a {
      color: #0067c5;
    }

    #app-info-message-header {
      font-family: 'Scania Sans Bold', Helvetica, Arial, sans-serif;
    }

    &.hidden {
      display: none;
    }
  }
}

#scania-banner {
  position: relative;
  z-index: 100;

  &.hidden {
    display: none;
  }
}

#center {
  width: 100%;
  overflow: auto;
  position: relative;
}
